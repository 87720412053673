export function isAdmin(key: string | undefined) {
    return key &&
        [
            '7KQWkLzkCqvncLPxZufEoKcPo8Zyf3EmhJUXChukr5EZ',
            'ERt2rfZvK1GQtkBbe1GTsQoRbchW9WyUFmE8ShRLSWyE',
            '8gN2EUnvcLJ9K7nTSSdU8PJ7cxR4b7hvBKwY6AaGURUj',
            '6oLsCsUczyRYDtqka4HNoTScWVYc3Jghh1aQCY6EsTw4',
            'PRQjECi1hV8EkxvFQ3SW9FBCSoaD8eoogHy9XCkXNDX',
        ]
            .includes(key);
}