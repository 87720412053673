import {Link} from "react-router-dom";
import Logo from "./logo.png";
import { isAdmin } from "../../methods/authorize";
import React from "react";
import { WalletContext } from "../../main/App";
import "./index.css";
import CreditPanel from "../Panels/CreditPanel";
import Clock from "../Clock";

export default function MainMenu() {
    let wallet = React.useContext(WalletContext);
    const pathname = window.location.pathname;

    return(
        <div className="main-menu" style={{paddingTop: '30px', paddingBottom: '20px'}}>
            <div style={{display: 'flex'}} className="list-items-menu">
                <img alt='' className="main-logo" src={Logo}/>
                <div>
                    <p className="menuItem">
                        <Link to="/" className={pathname === '/' ? 'activeMenuLink' : 'menuLink'}>
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={pathname === '/' ? '#ffcc00' : '#ffcc0093'}><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M21.58,16.09l-1.09-7.66C20.21,6.46,18.52,5,16.53,5H7.47C5.48,5,3.79,6.46,3.51,8.43l-1.09,7.66 C2.2,17.63,3.39,19,4.94,19h0c0.68,0,1.32-0.27,1.8-0.75L9,16h6l2.25,2.25c0.48,0.48,1.13,0.75,1.8,0.75h0 C20.61,19,21.8,17.63,21.58,16.09z M11,11H9v2H8v-2H6v-1h2V8h1v2h2V11z M15,10c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1 C16,9.55,15.55,10,15,10z M17,13c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C18,12.55,17.55,13,17,13z"/></g></g></svg>
                            GAME&nbsp;LOBBY
                        </Link>
                    </p>
                    <p className="menuItem">
                        <Link to="/history" className={pathname === '/history' ? 'activeMenuLink' : 'menuLink'}>
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={pathname === '/history' ? '#ffcc00' : '#ffcc0093'}><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M9,4v1.38c-0.83-0.33-1.72-0.5-2.61-0.5c-1.79,0-3.58,0.68-4.95,2.05l3.33,3.33h1.11v1.11c0.86,0.86,1.98,1.31,3.11,1.36 V15H6v3c0,1.1,0.9,2,2,2h10c1.66,0,3-1.34,3-3V4H9z M7.89,10.41V8.26H5.61L4.57,7.22C5.14,7,5.76,6.88,6.39,6.88 c1.34,0,2.59,0.52,3.54,1.46l1.41,1.41l-0.2,0.2c-0.51,0.51-1.19,0.8-1.92,0.8C8.75,10.75,8.29,10.63,7.89,10.41z M19,17 c0,0.55-0.45,1-1,1s-1-0.45-1-1v-2h-6v-2.59c0.57-0.23,1.1-0.57,1.56-1.03l0.2-0.2L15.59,14H17v-1.41l-6-5.97V6h8V17z"/></g></g></svg>
                            RACE&nbsp;HISTORY
                        </Link>
                    </p>
                    <p className="menuItem">
                        <Link to="/leaders" className={pathname === '/leaders' ? 'activeMenuLink' : 'menuLink'}>
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={pathname === '/leaders' ? '#ffcc00' : '#ffcc0093'}><rect fill="none" height="24" width="24"/><g><path d="M7.5,21H2V9h5.5V21z M14.75,3h-5.5v18h5.5V3z M22,11h-5.5v10H22V11z"/></g></svg>
                            LEADERBOARD
                        </Link>
                    </p>
                    <p className="menuItem">
                        <Link to="/halloffame" className={pathname === '/halloffame' ? 'activeMenuLink' : 'menuLink'}>
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={pathname === '/halloffame' ? '#ffcc00' : '#ffcc0093'}><rect fill="none" height="24" width="24"/><path d="M19,5h-2V3H7v2H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94c0.63,1.5,1.98,2.63,3.61,2.96V19H7v2h10v-2h-4v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z"/></svg>
                            HALL&nbsp;OF&nbsp;FAME
                        </Link>
                    </p>
                    <p className="menuItem">
                        <Link to="/join" className={pathname === '/join' ? 'activeMenuLink' : 'menuLink'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={pathname === '/join' ? '#ffcc00' : '#ffcc0093'}><path d="M0 0h24v24H0z" fill="none"/><path d="M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z"/></svg>
                            JOIN SOLRACER
                        </Link>
                    </p>
                    <p className="menuItem">
                        <Link to="/shop" className={pathname === '/shop' ? 'activeMenuLink' : 'menuLink'}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={pathname === '/shop' ? '#ffcc00' : '#ffcc0093'}><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
                            SHOP
                        </Link>
                    </p>
                    {isAdmin(wallet.key) ?
                        <p className="menuItem">
                            <Link to="/admin" className={pathname === '/admin' ? 'activeMenuLink' : 'menuLink'}>
                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={pathname === '/howtoplay' ? '#ffcc00' : '#ffcc0093'}><g><rect fill="none" height="24" width="24"/></g><g><path d="M19.94,9.06C19.5,5.73,16.57,3,13,3C9.47,3,6.57,5.61,6.08,9l-1.93,3.48C3.74,13.14,4.22,14,5,14h1l0,2c0,1.1,0.9,2,2,2h1 v3h7l0-4.68C18.62,15.07,20.35,12.24,19.94,9.06z M12.5,14c-0.41,0-0.74-0.33-0.74-0.74c0-0.41,0.33-0.73,0.74-0.73 c0.41,0,0.73,0.32,0.73,0.73C13.23,13.67,12.92,14,12.5,14z M14.26,9.68c-0.44,0.65-0.86,0.85-1.09,1.27 c-0.09,0.17-0.13,0.28-0.13,0.82h-1.06c0-0.29-0.04-0.75,0.18-1.16c0.28-0.51,0.83-0.81,1.14-1.26c0.33-0.47,0.15-1.36-0.8-1.36 c-0.62,0-0.92,0.47-1.05,0.86l-0.96-0.4C10.76,7.67,11.46,7,12.5,7c0.86,0,1.45,0.39,1.75,0.88C14.51,8.31,14.66,9.1,14.26,9.68z"/></g></svg>
                                ADMIN
                            </Link>
                        </p> :
                        <p className="menuItem">
                            <Link to="/howtoplay" className={pathname === '/howtoplay' ? 'activeMenuLink' : 'menuLink'}>
                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={pathname === '/howtoplay' ? '#ffcc00' : '#ffcc0093'}><g><rect fill="none" height="24" width="24"/></g><g><path d="M19.94,9.06C19.5,5.73,16.57,3,13,3C9.47,3,6.57,5.61,6.08,9l-1.93,3.48C3.74,13.14,4.22,14,5,14h1l0,2c0,1.1,0.9,2,2,2h1 v3h7l0-4.68C18.62,15.07,20.35,12.24,19.94,9.06z M12.5,14c-0.41,0-0.74-0.33-0.74-0.74c0-0.41,0.33-0.73,0.74-0.73 c0.41,0,0.73,0.32,0.73,0.73C13.23,13.67,12.92,14,12.5,14z M14.26,9.68c-0.44,0.65-0.86,0.85-1.09,1.27 c-0.09,0.17-0.13,0.28-0.13,0.82h-1.06c0-0.29-0.04-0.75,0.18-1.16c0.28-0.51,0.83-0.81,1.14-1.26c0.33-0.47,0.15-1.36-0.8-1.36 c-0.62,0-0.92,0.47-1.05,0.86l-0.96-0.4C10.76,7.67,11.46,7,12.5,7c0.86,0,1.45,0.39,1.75,0.88C14.51,8.31,14.66,9.1,14.26,9.68z"/></g></svg>
                                HOW TO PLAY
                            </Link>
                        </p>
                    }
                </div>
                <div style={{width: '60px'}} />
            </div>
            <div className="menu-bottom">
                <CreditPanel/>
                <Clock/>
            </div>
        </div>
    );
}
