import GameDetails from '../GameDetails'
import React, { useEffect } from "react";
import { WalletContext } from "../../../main/App";

export default function GameList() {
    let wallet = React.useContext(WalletContext);

    useEffect(() => {
        wallet.player.loadGames();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="GameLobby">
            {wallet.player.games.map(game => <GameDetails key={game.id} game={{...game}} loadGames={wallet.player.loadGames}/>)}
            {wallet.player.games.length > 0 &&
                <div style={{marginTop: '5px', marginLeft: '50px', textAlign: 'left'}}>
                    <button className="link-button" style={{color: '#ffce00'}} onClick={wallet.player.loadGames}>&gt; refresh</button>
                </div>
            }
        </div>
    );
}
