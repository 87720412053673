import React from "react";

export default function TeamHelpText() {

    return (
        <div className='flexColumn' style={{marginTop: '25px'}}>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                Which Team will you join?
            </div>
            <div style={{textAlign: 'center', width: '75pct'}}>
                To play Solracer, select a Team.
            </div>
            <div style={{textAlign: 'center', width: '75pct'}}>
                Race your NFT's from that collection and WIN!
            </div>
            <div style={{textAlign: 'center', width: '75pct'}}>
                The winning Team will be announced at the end of each season.
            </div>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                Good luck in the Races!
            </div>
            <div className='flexRow' style={{gap: '0px'}}>
                <img alt='' src='solcar-64.png'/>
            </div>
        </div>
    );
}
