import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main/App';
import {BrowserRouter, Route, Routes} from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App screen="lobby"/>}/>
                <Route path="/lobby" element={<App screen="lobby"/>}/>
                <Route path="/history" element={<App screen="history"/>}/>
                <Route path="/leaders" element={<App screen="leaders"/>}/>
                <Route path="/leaderteams" element={<App screen="leaderteams"/>}/>
                <Route path="/halloffame" element={<App screen="halloffame"/>}/>
                <Route path="/join" element={<App screen="join"/>}/>
                <Route path="/joinpay" element={<App screen="joinpay"/>}/>
                <Route path="/admin" element={<App screen="admin"/>}/>
                <Route path="/howtoplay" element={<App screen="howtoplay"/>}/>
                <Route path="/shop" element={<App screen="shop"/>}/>
                <Route path="/shoptokens" element={<App screen="shoptokens"/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

