import { PhantomProvider } from "../../main/App";
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, TransactionSignature } from "@solana/web3.js";

const web3 = require("@solana/web3.js");

export async function purchase(provider: PhantomProvider, amount_sol: number) {
    if (!provider.publicKey) return;

    console.log('wallet ' + provider.publicKey + ' is purchasing ' + amount_sol + ' SOL...');

    const playerPubkey = provider.publicKey;
    const bankPubkey = new PublicKey(process.env.REACT_APP_BANK_WALLET_ADDRESS!);
    const lamports = amount_sol * LAMPORTS_PER_SOL;

    // Connect to cluster
    const connection = new web3.Connection(web3.clusterApiUrl('mainnet-beta'), 'confirmed',);

    // Create a TX object
    let transaction = new Transaction({
        feePayer: playerPubkey,
        recentBlockhash: (await connection.getLatestBlockhash()).blockhash
    });

    // Add instructions to the tx
    transaction.add(
        SystemProgram.transfer({
            fromPubkey: playerPubkey,
            toPubkey: bankPubkey,
            lamports: lamports,
        })
    );

    // Get the TX signed by the wallet (signature stored in-situ)
    await provider.signTransaction(transaction);

    // Send the TX to the network
    let signature;
    await connection.sendRawTransaction(transaction.serialize())
        .then((sig: TransactionSignature) => {
            signature = sig;
            console.log(`Transaction: ${sig}`);
            connection.confirmTransaction(sig);
        })
        .catch(console.error);

    return signature;
}
