import React, { useState } from "react";
import TeamSelectorIcon from "../TeamSelectorIcon";
import Popup from "../../Popup";
import TeamPopup from "../TeamPopup";
import { teams } from "../../../helpers/config";

export default function TeamSelector() {
    const [team, setTeam] = useState<number | undefined>(undefined);
    const [isPopup, setIsPopup] = useState(false);

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    function showTeam(team: number) {
        setTeam(team);
        togglePopup();
    }

    return (
        <div className='flexColumn'>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                Choose your team:
            </div>
            <div className='flexRow'>
                <TeamSelectorIcon image={teams[0].image} title={'Team ' + teams[0].name} selectFn={() => showTeam(0)}/>
                <TeamSelectorIcon image={teams[1].image} title={'Team ' + teams[1].name} selectFn={() => showTeam(1)}/>
            </div>
            <div className='flexRow'>
                <TeamSelectorIcon image={teams[2].image} title={'Team ' + teams[2].name} selectFn={() => showTeam(2)}/>
                <TeamSelectorIcon image={teams[3].image} title={'Team ' + teams[3].name} selectFn={() => showTeam(3)}/>
            </div>

            {isPopup && team !== undefined && <Popup content={
                <TeamPopup team={teams[team]}/>
            } handleClose={togglePopup}/>}

        </div>
    );
}
