import MainMenu from "../../components/MainMenu";
import React, { useEffect, useState } from "react";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
import { isAdmin } from "../../methods/authorize";
import { WalletContext } from "../../main/App";
import { fn_url } from "../../helpers/config";
import "./index.css";

export default function Admin() {
    const wallet = React.useContext(WalletContext);
    const [bankBalance, setBankBalance] = useState<number | undefined>(undefined);
    const [gameMode, setGameMode] = useState()
    const [entryFee, setEntryFee] = useState()

    const changeGameMode = (value: any) => {
        console.log('setting game mode to: ' + value);
        setGameMode(value);
        fetch(fn_url + "configset?walletKey=" + wallet.key + '&key=gameMode&value=' + value).then(response => response.json());
    }

    const changeEntryFee = (value: any) => {
        console.log('setting entry fee to: ' + value);
        setEntryFee(value);
        fetch(fn_url + "configset?walletKey=" + wallet.key + '&key=entryFee&value=' + value).then(response => response.json());
    }

    const loadBankBalance = async () => {
        console.log('loading bank balance...');
        let connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
        connection.getBalance(new PublicKey(process.env.REACT_APP_BANK_WALLET_ADDRESS!)).then(function (value) {
            setBankBalance(Math.floor(value / 10000000) / 100);
        })
    };

    const loadConfig = async () => {
        console.log('loading config...');
        let response: any[] = await fetch(fn_url + "config?walletKey=" + wallet.key).then(response => response.json());
        setGameMode(response.find(cfg => cfg.key === 'gameMode').value);
        setEntryFee(response.find(cfg => cfg.key === 'entryFee').value);
    }

    useEffect(() => {
        loadBankBalance();
        loadConfig();
    });

    return (
        <div className="admin" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <MainMenu/>
            {isAdmin(wallet.key) ?
                <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Admin Page
                    </div>
                    <div style={{marginBottom: '10px'}}>
                        <div style={{fontSize: '20px', marginBottom: '10px'}}>Bank balance:</div>
                        {(bankBalance !== undefined) &&
                            <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                <div style={{padding: '7px 0px 0px 7px'}}><img alt='coin' height='64px' width='64px' src='solcoin.png'/></div>
                                <div style={{fontSize: '30px'}}>{bankBalance} SOL</div>
                                <div style={{padding: '7px 0px 0px 0px'}}>
                                    <a title='solscan' target='banksolscan'
                                       href={'https://solscan.io/account/' + process.env.REACT_APP_BANK_WALLET_ADDRESS + '#solTransfers'}><img
                                        alt='' src='solscan.png'/></a>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Autoscheduler
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Choose game mode:
                        <select style={{marginLeft: '15px'}} name='gameMode'
                                onChange={(event) => changeGameMode(event.target.value)} value={gameMode}>
                            <option value="FREE">FREE (start free-to-play games)</option>
                            <option value="SOL">SOL (start SOL payment games)</option>
                            <option value="ALTERNATE">ALTERNATE (between FREE and SOL games)</option>
                            <option value="STOP">STOP (dont schedule new games)</option>
                        </select>

                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Choose entry fee:
                        <select style={{marginLeft: '15px'}} name='entryFee'
                                onChange={(event) => changeEntryFee(event.target.value)} value={entryFee}>
                            <option value="0.01">0.01 SOL</option>
                            <option value="0.02">0.02 SOL</option>
                            <option value="0.05">0.05 SOL</option>
                            <option value="0.1">0.1 SOL</option>
                            <option value="0.2">0.2 SOL</option>
                            <option value="0.5">0.5 SOL</option>
                        </select>

                    </div>
                </div> :
                <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Not authorized
                    </div>
                </div>
            }
        </div>
    );
}

