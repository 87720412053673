import MainMenu from "../../components/MainMenu";
import React, { useState } from "react";
import "./index.css";
import { fn_url, team_entry_fee } from "../../helpers/config";
import { WalletContext } from "../../main/App";
import { sendRSTK } from "../../methods/tokens";

export default function Joinpay() {
    const wallet = React.useContext(WalletContext);
    let [teamName, setTeamName] = useState('');
    let [collection, setCollection] = useState('');

    const payTeamEntryFee = async () => {
        console.log("paying team entry fee...");
        console.log("Team name: " + teamName);
        console.log("Collection: " + collection);
        console.log("Wallet address: " + wallet.key);
        if (!wallet.key) return;
        // setIsClicked(true);
        let amountRSTK = team_entry_fee;
        let signature = await sendRSTK(wallet.provider, wallet.key, team_entry_fee);
        console.log('signature was ' + signature);
        if (signature !== null) {
            var feepayResp = await fetch(fn_url + "feepay", {
                method: 'POST',
                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    teamName: teamName,
                    collection: collection,
                    walletKey: wallet.key,
                    amount: amountRSTK,
                })
            });

            console.log(JSON.stringify(feepayResp.json()));
            if (feepayResp.ok) {
                // togglePopup();
                // props.successFn();
            }

        }
    }

    return (
        <div className='hall-of-fame' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '40px 0px 0px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Join Solracer - Payment
                </div>
                <div className='flexColumn' style={{alignItems: 'flex-start'}}>
                    <div>Please pay the Team Entry fee to join Solracer:</div>

                    <div>Team Name:</div>
                    <input className='textInput' onChange={(event) => setTeamName(event.target.value)}/>
                    <div>Link to collection on MagicEden:</div>
                    <input className='textInput' onChange={(event) => setCollection(event.target.value)}/>
                    <div>Team Entry fee:</div>
                    <div className='flexRow'>
                        <a target='restackcoin' href='https://www.restackcoin.com'><img alt='' height='50px' src='restackcoin-100.png'/></a>
                        <div>{team_entry_fee} RSTK</div>
                    </div>

                    <div>Why pay a fee? Our staff must: verify your collection, check the NFT project is not a rug,
                    set up a team banner in the game, download and cleanup the metadata and check the game client
                    runs with your NFTs :)</div>

                    <button className="clearButton" onClick={payTeamEntryFee}>Pay Entry Fee</button>

                </div>
            </div>
        </div>
    );
}
