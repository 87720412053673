import MainMenu from "../../components/MainMenu";
import React from "react";
import "./index.css";
import { teams } from "../../helpers/config";

export default function HallOfFame() {
    return (
        <div className='hall-of-fame' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '40px 0px 0px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Hall of Fame
                </div>
                <div>Who will win this season of Solracer?</div>

                <div className='flexRow' style={{marginTop: '20px'}}>
                    {teams.map(team => {return (
                        <img alt='' height='100px' width='100px' src={team.image} title={'Team ' + team.name} style={{borderRadius: '50px'}}/>
                    )})}
                </div>

                <div style={{marginTop: '15px'}}>There can only be one winning team!</div>
                <div style={{marginTop: '15px'}}>Start your engines and let's race...</div>
                <div style={{marginTop: '15px', fontSize: '25px', fontWeight: 'bold', fontStyle: 'italic'}}>VROOOOM!</div>
            </div>
        </div>
    );
}
