import React from "react";
import "./index.css";
import MainMenu from "../../components/MainMenu";
import LobbyMid from "../../components/Lobby/LobbyMid";
import LobbyRight from "../../components/Lobby/LobbyRight";

export default function Lobby() {
    return (
        <div className="lobby" style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <LobbyMid/>
            <LobbyRight/>
        </div>
    );
}
