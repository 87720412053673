import React from "react";
import GameList from "../../Game/GameList";

export default function LobbyMid() {
    return(
        <div style={{width: '450px', height: '368px', textAlign: 'center', marginTop: '80px'}}>
            <GameList/>
        </div>
    );
}
