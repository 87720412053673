import { teams } from "../config";

export function imageForNft(nft: string) {
    let collection = nft.split(" #")[0];
    let id = nft.split(" #")[1];
    return 'https://nft-images.netlify.app/' + collection.replace(' ', '') + '/image-' + id + '-75.png';
}

export function imageForTeam(team: string) {
    return teams.find(x => x.name === team)?.image;
}

export function teamForNft(nft: string) {
    let collection = nft?.split(" #")[0];
    return teams.find(x => x.collection === collection)?.name;
}
