import React from 'react';
import { WalletContext } from '../../../main/App';

export default function BuyPanel() {
    const wallet = React.useContext(WalletContext);
    return (
        <div style={{height: '50px', gap: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div>Need more NFTs?</div>
            <div>
                <a href={'https://magiceden.io/marketplace/' + wallet.player.team!.marketplace} target='magiceden'>
                    <button className='clearButton'>Browse&nbsp;here</button>
                </a>
            </div>
        </div>
    );
}
