import MainMenu from "../../components/MainMenu";
import React, { useState } from "react";
import "./index.css";

export default function HowToPlay() {
    const [page, setPage] = useState(0);

    const nextPage = async () => {
        setPage((page + 1) % 4);
    }

    return (
        <div className="how-to-play" style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            {page === 0 &&
                <div className="info" style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Solracer is an NFT racing game from <a href='https://restack.ai' target='restack' >restack.ai</a>.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        To play, you need to have Phantom wallet installed and then purchase an
                        NFT from magiceden.io from one of the teams racing in Solracer.
                    </div>
                    <a href='https://magiceden.io' target='magiceden' >
                        <img style={{marginBottom: '10px', height: '40px'}} alt='magiceden' src='magiceden-banner.png' />
                    </a>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When you have joined a team - click 'REGISTER' to join a game. All games are free to play,
                        all games have rewards! You can spend $NITRO to buy powerups and upgrades.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        We hope you like the game, it was developed by Tr0n.
                    </div>
                    <button className="playButton" style={{ marginBottom: '20px' }} onClick={nextPage}>NEXT</button>
                </div>
            }
            {page === 1 &&
                <div className="info" style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Gameplay
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Games are scheduled at specific times and will play automatically. If there
                        are not enough players to start, the game will wait.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When the game is ready to start - click on the 'PLAY' button to jump into the game.
                        The race is completely random - the Solracer NFTs race by themselves and one of them is the winner.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        If you are not here to watch the game when it starts - don't worry, your Solcar will still race :)
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        You can watch a replay of any Solracer game later.
                    </div>
                    <button className="playButton" style={{ marginBottom: '20px' }} onClick={nextPage}>NEXT</button>
                </div>
            }
            {page === 2 &&
                <div className="info" style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Your Data
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        After you connect your Phantom wallet, Solracer will inspect your wallet
                        and look for NFT's from the Solracer teams.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When you register to play, the game will record your Phantom wallet address and where
                        you are connecting from.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Rewards are given as $NITRO, the in-game token for Solracer. You can spend $NITRO on
                        in-game rewards, which are bound to your NFT.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        We will handle your data responsibly, and by playing Solracer you agree to this.
                    </div>
                    <button className="playButton" style={{ marginBottom: '20px' }} onClick={nextPage}>NEXT</button>
                </div>
            }
            {page === 3 &&
                <div className="info" style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Something Wrong?
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        If you experience a problem with the game or the payments, please
                        contact us <a target='discord-gamechannel'
                                      href='https://discord.com/channels/878041551442432040/966059783260110889'>on
                        discord</a> and
                        our team will assist you.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Tell us: which game you entered, your NFT team and number, your wallet address, and the nature of the problem.
                    </div>
                    <button className="playButton" style={{ marginBottom: '20px' }} onClick={nextPage}>NEXT</button>
                </div>
            }
        </div>
    );
}
