import React from "react";
import NftSelector from "../../components/NftSelector";
import ShopBalance from "../../components/Shop/ShopBalance";
import "./index.css";
import MainMenu from "../../components/MainMenu";

export default function Shop() {
    // const wallet = React.useContext(WalletContext);
    // const [_, setItems] = useState<string[] | undefined>(undefined);
    //
    // if (wallet.player.selectedNft === -1 && wallet.player.nfts !== undefined && wallet.player.nfts.length > 0) {
    //     wallet.player.selectedNft = wallet.player.nfts[0].id;
    // }
    //
    const loadItems = async (nft: number) => {
    //     console.log('Loading items for nft ' + nft + '...');
    //     setItems(undefined);
    //     let items = await fetch('/.netlify/functions/items?nft=' + nft).then(response => response.json());
    //     setItems(items);
    }
    //
    // const refresh = async () => {
    //     wallet.player.loadRewards();
    //     loadItems(wallet.player.selectedNft);
    // }
    //
    // useEffect(() => {
    //     loadItems(wallet.player.selectedNft);
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="shop" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <MainMenu/>
            <div className='flexColumn' style={{width: '660px', padding: '40px 0px 0px 70px', justifyContent: 'flex-start'}}>
                <div style={{fontSize: '30px', marginBottom: '10px'}}>Solracer Shop</div>
                <NftSelector message={'Select an NFT to buy items for'} pagesize={5} selectFn={loadItems}/>
                <div style={{fontSize: '75px', fontWeight: 'bold', fontFamily: 'Bangers', color: '#ffce00'}}>
                    COMING SOON!
                </div>
                {/*<div style={{ minHeight: '340px', maxHeight: '340px', overflow: 'scroll' }}>*/}
                    {/*<div className='flexRow'>*/}
                    {/*    <ShopItem items={items} id='0101' price={10} rarity='common' name='Cowboy Hat' desc='Shades you from the sun' successFn={refresh}/>*/}
                    {/*    <ShopItem items={items} id='0101b' price={10} rarity='common' name='Cowboy Hat' desc='Shades you from the sun' successFn={refresh}/>*/}
                    {/*    <ShopItem items={items} id='0101c' price={10} rarity='common' name='Cowboy Hat' desc='Shades you from the sun' successFn={refresh}/>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<ShopCoins/>*/}
                <ShopBalance/>
                {/*<Link to='/' style={{ bottom: '40px' }}><button className="playButton">Close</button></Link>*/}
            </div>
        </div>
    );
}
