import React, { useState } from "react";
import { fn_url } from "../../../helpers/config";
import { Team } from "../../../helpers/types";
import { WalletContext } from "../../../main/App";

export default function TeamPopup(props: {team: Team}) {
    const wallet = React.useContext(WalletContext);
    const [isClicked, setIsClicked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const selectTeam = async () => {
        setIsClicked(true);

        console.log("Selecting team...");
        var fnResp = await fetch(fn_url + "teamselect", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                team: props.team.name,
                walletKey: wallet.key,
            })
        });

        if (fnResp.ok) {
            setIsSuccess(true);
            wallet.player.setTeam(props.team);
        }
    }

    return (
        <div>
            <h1 style={{marginTop: '0'}}><b>Team {props.team.name}</b></h1>

            <img alt='' src={props.team.image} title={'Team ' + props.team.name} width='100px' height='100px' style={{borderRadius: '50px'}}/>

            <div style={{marginTop: '25px', fontSize: '20px'}}>Will you race for team: {props.team.name} ?</div>

            <div style={{marginTop: '25px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {   isSuccess ? <div>Success!</div>
                    : isClicked ? <div>joining team...</div>
                    : <button className="playButton" onClick={() => selectTeam()}>SELECT</button>
                }
            </div>

            <div style={{marginTop: '25px', fontSize: '20px'}}>You can always change teams later</div>

        </div>

    );
}
