import MainMenu from "../../components/MainMenu";
import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

export default function Join() {
    return (
        <div className='hall-of-fame' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '40px 0px 0px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Join Solracer
                </div>
                <div className='flexColumn' style={{alignItems: 'flex-start'}}>
                    <div>Would you like your NFT collection to join Solracer?</div>

                    <img alt='' src='solcar-64.png'/>

                    <div>1. Contact us via Restack.AI website.</div>
                    <a target='restack' href='https://www.restack.ai'><img alt='' height='50px' src='restack-banner.png'/></a>
                    <div>Tell us the collection and why you want to join Solracer. Your collection
                        must be whitelisted on MagicEden. No rugs! Serious players only :)</div>

                    <div>2. Buy Restack coins.</div>
                    <div className='flexRow'>
                        <a target='restackcoin' href='https://www.restackcoin.com'><img alt='' height='50px' src='restackcoin-100.png'/></a>
                        <div>Buy restack coins from <a target='restackcoin' href='https://www.restackcoin.com'>www.restackcoin.com</a></div>
                    </div>

                    <div>3. Pay the Team entry fee.</div>
                    <div className='flexRow'>
                        <a target='restackcoin' href='https://www.restackcoin.com'><img alt='' height='50px' src='restackcoin-100.png'/></a>
                        <div>You will need to <Link to='/joinpay'>pay an entry fee</Link> in Restack coins for your Team</div>
                    </div>
                    <div>Once you pay the fee, your NFT collection can race in Solracer</div>

                </div>
            </div>
        </div>
    );
}
