import React, { useEffect, useState } from "react";
import Popup from "../../Popup";
import { Game } from "../../../helpers/types";
import { imageForNft, teamForNft } from "../../../helpers/nft-utils";
import {
    finished_at_seconds,
    fn_url,
    race_at_seconds,
    rake_percent,
    ready_at_seconds, unity_url,
    waiting_reload_seconds
} from "../../../helpers/config";
import { purchase } from "../../../methods/bank";
import { WalletContext } from "../../../main/App";

export default function GameDetails(props: {game: Game, loadGames: any}) {
    const wallet = React.useContext(WalletContext);
    const [lastLoadGames] = useState(new Date());
    const [sentKeyReq, setSentKeyReq] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [game, setGame] = useState(props.game);
    const [now, setNow] = useState<Date>(new Date());
    let nft : string;


    useEffect(() => {
        const interval = setInterval(() => setNow(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);

    const clickButton = () => {
        if (game.action === 'REGISTER' || game.action === 'REGISTERED'  || game.action === 'WAITING' || game.action === 'WATCH') {
            togglePopup();
        } else if (game.action === 'PLAY' || game.action === 'SPECTATE') {
            openUnityClient();
        }
    }

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    const isFull = () => {
        return game.players >= game.maxPlayers;
    }

    const openUnityClient = () => {
        setIsPopup(false);
        try {
            window.open(unity_url + game.results[0].key, 'unity');

            // reload the page approximately when the game is finished
            let now = Date.now();
            let finishedAt = new Date(game.scheduled_at).getTime() + finished_at_seconds * 1000;
            if (finishedAt > now) {
                console.log('will reload in ' + ((finishedAt - now) / 1000).toFixed(1) + ' seconds');
                setTimeout(() => {
                    props.loadGames();
                    wallet.player.loadRewards();
                }, finishedAt - now);
            }
        } catch (e) {
            console.error('openInUnityClient cannot determine game key for game id: ' + game.id);
        }
    }

    const registerForGame = async () => {
        if (game.players >= game.maxPlayers) return;

        setIsClicked(true);

        // take a payment via phantom wallet for SOL games
        let signature;
        if (game.mode === 'SOL') {
            if (game.entryFee === null) return;
            let amount_sol = game.entryFee;
            if (amount_sol === 0.0) return;
            if (amount_sol > 1.0) return;

            console.log("purchasing entry fee " + game.entryFee + " SOL...");
            signature = await purchase(wallet.provider, amount_sol);
            console.log('registered successfully with signature : ' + signature);
        }

        console.log("registering for game...");
        var registerResp = await fetch(fn_url + "register", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                game_id: game.id,
                walletKey: wallet.key,
                nft: wallet.player.selectedNft,
                team: teamForNft(wallet.player.selectedNft!),
                signature: signature,
                solBalance: wallet.player.sol,
                nftsCount: wallet.player.nfts?.length
            })
        })
            .then(response => response.json());

        console.log(JSON.stringify(registerResp));
        setGame(registerResp);
        setIsClicked(false);
    }

    const getGameKey = async () => {
        console.log("getting game key...");
        var registerResp = await fetch(fn_url + "gamekey", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({id: game.id})
        })
            .then(response => response.json());
        game.results = [registerResp];
    }

    function secondsDiff(from: Date, to: Date) {
        return Math.round((to.getTime() - from.getTime()) / 1000);
    }

    function prettyTime(seconds: number) {
        return  seconds > 3600 ? Math.floor(seconds / 3600) + " hours" :
            seconds > 120 ? Math.floor(seconds / 60) + " minutes" :
            seconds > 60 ? "1 minute" : seconds > 45 ? "45 seconds" : seconds > 30 ? "30 seconds" : seconds > 15 ? "15 seconds" : " a moment...";
    }

    // if unselected, select first nft if possible
    if (game.action === 'REGISTER' && !wallet.player.selectedNft && wallet.player.nfts && wallet.player.nfts.filter(x => x.collection === wallet.player.team?.collection).length > 0) {
        wallet.player.selectedNft = wallet.player.nfts.filter(x => x.collection === wallet.player.team?.collection)[0].name;
    }

    // figure out which nft to show
    let registration = game.registrations.find(x => x.walletKey === wallet.key);
    nft = registration ? registration.nft : wallet.player.selectedNft!;

    // what is the icon title text
    let title = game.mode === 'SOL' ? 'SOL game' : 'Win 1 $NITRO';

    // set up timer
    let playAt = (game.played_at) ? new Date(game.played_at) : new Date(game.scheduled_at);
    let readyAt = new Date(playAt.getTime() + ready_at_seconds * 1000);
    let raceAt = new Date(playAt.getTime() + race_at_seconds * 1000);
    let finishedAt = new Date(playAt.getTime() + finished_at_seconds * 1000);

    let timerText;
    if (now < playAt) {
        timerText = "ready in " + prettyTime(secondsDiff(now, playAt));
    } else if (now >= playAt && game.played_at === null && game.players < game.minPlayers) {
        timerText = "waiting players (" + (game.minPlayers-game.players) + ")"
        game.action = registration ? 'WAITING' : 'REGISTER';
        if (new Date().getTime() - lastLoadGames.getTime() > waiting_reload_seconds * 1000) {
            props.loadGames();
        }
    } else if (now >= playAt && now < readyAt) {
        timerText = "creating game...";
        let secondsTilReady = secondsDiff(now, readyAt);
        game.action = 'READY IN ' + secondsTilReady;
        if (secondsTilReady < 5 && !sentKeyReq) {
            setSentKeyReq(true);
            getGameKey();
        }
    } else if (now >= readyAt && now < raceAt) {
        timerText = "starts in " + secondsDiff(now, raceAt) + "...";
        game.action = game.registrations.find(g => g.walletKey === wallet.key) ? 'PLAY' : 'SPECTATE';
    } else if (now >= raceAt && now < finishedAt) {
        game.action = game.registrations.find(g => g.walletKey === wallet.key) ? 'PLAY' : 'SPECTATE';
        timerText = "game playing...";
    } else if (now >= finishedAt) {
        game.action = 'WATCH';
        timerText = "game finished";
    }

    return (
        <div className="gameSchedule" style={{display: 'flex', gap: '12px'}}>
            <div className="gameInfo" style={{width: '250px'}}>
                <div style={{float: 'right', width: '200px'}}>
                    <div className="gameName">{game.name}</div>
                    <div className="gameDesc">{timerText}</div>
                </div>
            </div>

            <div style={{display: 'inline', marginTop: '15px', paddingTop: '5px'}}>
                <button className="playButton" onClick={clickButton}>{game.action}</button>
            </div>

            <div style={{display: 'inline', marginTop: '19px', paddingTop: '5px'}}>
                {game.mode === 'SOL' && <img title={title} alt='' style={{height: '32px', width: '32px'}} src='solcoin.png' /> }
                {game.mode === 'FREE' && <img title={title} alt='' style={{height: '30px', width: '30px'}} src='nitro-32.png' /> }
            </div>

            {isPopup && game.action === 'WAITING' && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Waiting for players...</b></h1>

                    <div style={{marginTop: '25px', fontSize: '20px'}}>The game will start when the minimum number of players are registered. VROOM!</div>

                    <div style={{marginTop: '25px', fontSize: '20px'}}>We need this many Solracers to play: {game.minPlayers}</div>

                    <div style={{marginTop: '25px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className="playButton" onClick={togglePopup}>OKAY</button>
                    </div>

                    <div style={{fontSize: '20px'}}>{game.players} out of {game.maxPlayers} players</div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && wallet.player.nfts && wallet.player.nfts.length > 0 && <Popup content={
                <div>
                    <h1 style={{marginTop: '0', marginBottom: '10px'}}><b>Let's Play!</b></h1>

                    <div style={{marginBottom: '15px'}}>Are you ready to race with this NFT?</div>

                    <div style={{height: '75px', marginBottom: '5px'}}>
                        <img alt='' style={{border: 'solid 2px #270238'}} title={nft} src={imageForNft(nft)}/>
                    </div>

                    <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '20px'}}>{nft}</div>

                    <div style={{fontSize: '25px'}}>{game.name}</div>
                    <div style={{marginTop: '0px'}}>Starts at {new Date(game.scheduled_at).toLocaleString()}</div>


                    {game.mode === 'SOL' && (
                            <div>
                                <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{fontSize: '20px'}}>Entry Fee:</div>
                                    <div style={{padding: '7px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='solcoin.png' /></div>
                                    <div style={{fontSize: '20px'}}>{game.entryFee + ' SOL'}</div>
                                </div>
                                <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{fontSize: '20px'}}>Reward:</div>
                                    <div style={{padding: '7px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='solcoin.png' /></div>
                                    <div style={{fontSize: '20px'}}>{parseFloat((game.entryFee * Math.max(game.minPlayers, game.players) * (100 - rake_percent) / 100).toFixed(4))} SOL</div>
                                </div>
                        </div>
                    )}
                    {game.mode === 'FREE' && (
                        <div>
                            <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{fontSize: '20px'}}>Entry Fee:</div>
                                <div style={{fontSize: '20px'}}>FREE</div>
                            </div>
                            <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{fontSize: '20px'}}>Reward:</div>
                                <div style={{padding: '7px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='nitro-32.png' /></div>
                                <div style={{fontSize: '20px'}}>1 $NITRO</div>
                            </div>
                        </div>
                    )}

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {game.action === 'REGISTER' && (isFull() ? <div>Game is full :(</div> : (isClicked ? <div>Sending...</div> : <button className="playButton" onClick={registerForGame}>REGISTER</button>))}
                        {game.action === 'REGISTERED' && <div>You are registered :)</div>}
                    </div>

                    <div style={{fontSize: '20px'}}>{game.players} out of {game.maxPlayers} players</div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && wallet.player.nfts && wallet.player.nfts.length === 0 && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Want to Play?</b></h1>

                    <p style={{fontSize: '20px'}}>
                        To go in a duck race, you need a duck :)
                    </p>

                    <p style={{fontSize: '15px'}}>
                        Head on over to magiceden.io and puchase an NFT from any team in the Solracer NFT collections. The more style, the better!
                    </p>

                    <a href='https://magiceden.io/marketplace/apexducks' target='magiceden' >
                        <img alt='magiceden' src='magiceden-banner.png' />
                    </a>

                    <p>
                        Once you have purchased an NFT from a Solracer team, you can play.
                    </p>

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className="playButton" onClick={togglePopup}>OKAY</button>
                    </div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && !wallet.key && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Want to Play?</b></h1>

                    <p style={{fontSize: '20px'}}>
                        Connect your Wallet to play.
                    </p>

                    <p style={{fontSize: '15px'}}>
                        If you don't have Phantom Wallet installed, download if from here:
                    </p>

                    <a href='https://phantom.app' target='phantom' >
                        <img alt='phantom' src='phantom-banner.png' />
                    </a>

                    <p>
                        You also need any "Solracer" team NFT in your wallet to play the game.
                    </p>

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className="playButton" onClick={togglePopup}>OKAY</button>
                    </div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action === 'WATCH' && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>What a race</b></h1>
                    <p>This race has ended, and what a race it was!</p>

                    <div style={{height: '75px', marginBottom: '5px'}}>
                        <img alt='' style={{border: 'solid 2px #270238'}}
                             title={'ApexDucks #' + game.winner}
                             src={imageForNft(game.winner)}
                        />
                    </div>

                    <div style={{fontSize: '25px'}}>{game.name}</div>
                    <div style={{marginTop: '0px'}}>Played at {new Date(game.played_at).toLocaleString()}</div>

                    <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{fontSize: '20px'}}>Winner:</div>
                        <div style={{fontSize: '20px'}}>{game.winner}</div>
                    </div>

                    {game.mode === 'SOL' &&
                        <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{fontSize: '20px'}}>Reward:</div>
                            <div style={{padding: '7px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='solcoin.png' /></div>
                            <div style={{fontSize: '20px'}}>{game.payout} SOL</div>
                        </div>
                    }

                    {game.mode === 'FREE' &&
                        <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{fontSize: '20px'}}>Reward:</div>
                            <div style={{padding: '7px 0px 0px 7px'}}><img alt='coin' width='22px' height='22px' src='nitro-32.png' /></div>
                            <div style={{fontSize: '20px'}}>1 $NITRO</div>
                        </div>
                    }

                    <button className="playButton" onClick={openUnityClient} style={{marginTop: '15px'}}>WATCH</button>

                    <div style={{marginTop: '10px'}}>Click to watch the replay.</div>
                </div>


            } handleClose={togglePopup}/>}

        </div>
    );
}
