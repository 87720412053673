import { Game, Transaction } from "../../../helpers/types";
import { useEffect, useState } from "react";
import HistoryGameTransactionsLine from "../HistoryGameTransactionsLine";
import { fn_url } from "../../../helpers/config";

export default function HistoryGameTransactions(props: {game: Game}) {
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    const game = props.game;

    const loadTransactions = async () => {
        console.log("Loading transactions...");
        setTransactions(await fetch(fn_url + "transactions?game_id=" + game.id).then(response => response.json()));
    }

    useEffect(() => {
        loadTransactions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '3px'}}>
            {transactions.map(transaction =>
                <HistoryGameTransactionsLine transaction={transaction} game={game}/>
            )}
        </div>

    );
}