import Nft from "../../helpers/types";
import React, { useEffect, useState } from "react";
import { WalletContext } from "../../main/App";

export default function NftSelector(props: {message: string, pagesize: number, selectFn?: any }) {
    const wallet = React.useContext(WalletContext);
    const [page, setPage] = useState<number>(0);
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const nfts : Nft[] | undefined = wallet.player.nfts?.filter(x => x.collection === wallet.player.team?.collection);
    const pagesize = props.pagesize;

    const preloadImages = async (nextPage: number) => {
        nfts?.slice(nextPage * pagesize, (nextPage + 1) * pagesize).forEach(nft => {
            new Image().src = nft.image;
        });
    }

    useEffect(() => {
        preloadImages(1);
        if (!wallet.player.selectedNft) {
            // setSelected()
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function pageLeft() {
        if (page > 0) {
            setPage(page - 1);
        }
    }

    function pageRight() {
        if (nfts !== undefined) {
            if (page < (nfts.length / pagesize - 1)) {
                preloadImages(page + 2);
                setPage(page + 1);
            }
        }
    }

    function selectNft(name: string) {
        setSelected(name);
        wallet.player.selectedNft = name;
        if (props.selectFn) {
            props.selectFn(name);
        }
    }

    return (wallet.player.nfts && wallet.player.nfts.length > 0) ? (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                <div><img alt="left" width="20px" src="arrow-left-y-20.png" onClick={pageLeft}/></div>
                {nfts?.slice(page * pagesize, (page + 1) * pagesize).map(x =>
                    <div key={x.name}>
                        <img alt="" src={x.image} onClick={() => selectNft(x.name)}
                             title={x.name} style={{width: '75px', height: '75px', border: 'solid 2px ' + (x.name === selected ? 'yellow' : 'transparent')}}
                        />
                    </div>
                )}
                <div><img alt="right" width="20px" src="arrow-right-y-20.png" onClick={pageRight}/></div>
            </div>
            <div style={{textAlign: 'center', marginTop: '5px'}}>{selected ? 'You selected ' + selected : props.message}</div>
        </div>
    ) : (
        <div style={{padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                Want to play?
            </div>
            <div style={{textAlign: 'center', width: '75%'}}>
                Purchase a Solracer NFT to play the game :)
            </div>
        </div>
    );
}
