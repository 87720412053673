import React, { useState } from "react";
import Popup from "../../Popup";
import { imageForNft } from "../../../helpers/nft-utils";
import { WalletContext } from "../../../main/App";
import { fn_url } from "../../../helpers/config";


export default function RewardsPanel() {
    const wallet = React.useContext(WalletContext);
    const [isPopup, setIsPopup] = useState(false);

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    const claimReward = async () => {
        setIsPopup(false);
        await fetch(fn_url + "rewardclaim?game_id=" + wallet.player.nextReward?.id).then(response => response.json())
            .catch(e => console.log(e));
        wallet.player.nextReward = undefined;
        wallet.player.loadRewards();
    }

    let reward = wallet.player.nextReward;

    return (
        <div style={{textAlign: 'center', height: '185px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
            {reward ?
                <div>
                    <div>
                        <img alt='chest' style={{height: '100px'}} src='treasure-chest.png'/>
                    </div>
                    <div style={{gap: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div>You won a reward!</div>
                        <button className="clearButton" onClick={togglePopup}>Claim :)</button>
                    </div>
                </div>
            :
                <div style={{display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{}}>
                        Your balance is:
                    </div>
                    <img alt='coin' width='44px' height='44px' src='nitro-32.png' />
                    <div style={{fontWeight: 'bold'}}>
                        {wallet.player.tokens} $NITRO
                    </div>
                </div>
            }

            {isPopup && reward && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Congratulations!</b></h1>

                    <div style={{height: '75px', marginBottom: '5px'}}>
                        <img alt='' style={{border: 'solid 2px #270238'}}
                             title={reward.winner}
                             src={imageForNft(reward.winner)}
                        />
                    </div>

                    <div style={{fontSize: '25px'}}>{reward.name}</div>
                    <div style={{marginTop: '0px'}}>Played at {new Date(reward.played_at).toLocaleString()}</div>

                    <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{fontSize: '20px'}}>Winner:</div>
                        <div style={{fontSize: '20px'}}>{reward.winner}</div>
                    </div>

                    {reward.mode === 'FREE' &&
                        <div style={{marginBottom: '0px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{fontSize: '20px'}}>Reward:</div>
                            <div style={{padding: '7px 0px 0px 7px'}}><img alt='coin' width='22px' height='22px' src='nitro-32.png' /></div>
                            <div style={{fontSize: '20px'}}>1 $NITRO</div>
                        </div>
                    }
                    {reward.mode === 'SOL' &&
                        <div style={{marginBottom: '0px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{fontSize: '20px'}}>Reward:</div>
                            <div style={{padding: '7px 0px 0px 7px'}}><img alt='coin' width='22px' height='22px' src='solcoin.png' /></div>
                            <div style={{fontSize: '20px'}}>{reward?.payout} SOL</div>
                        </div>
                    }

                    {reward.mode === 'SOL' && reward.signature &&
                        <div style={{display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center', fontSize: '20px'}}>
                            <div>Transaction:</div>
                            <div>{reward.signature.slice(0,4)}...{reward.signature.slice(reward.signature.length-4)}</div>
                            <div style={{padding: '7px 0px 0px 0px'}}>
                                <a title='solscan' target={reward.signature.slice(0,20)} href={'https://solscan.io/tx/' + reward.signature}><img alt='' src='solscan.png'/></a>
                            </div>
                        </div>
                    }

                    <button className="playButton" onClick={claimReward} style={{marginTop: '15px'}}>OKAY</button>

                    <div style={{marginTop: '10px'}}>Your reward was paid out at {new Date(reward.paidout_at).toLocaleString()}</div>
                </div>
            } handleClose={togglePopup}/>}
        </div>
    );
}
