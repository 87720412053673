import { clusterApiUrl, Connection, PublicKey, Transaction } from "@solana/web3.js";
import { Connection as MetaConnection } from "@metaplex/js/";
import { Metadata, MetadataData } from "@metaplex-foundation/mpl-token-metadata";
import * as React from "react";
import { useEffect, useState } from "react";

import "./App.css";
import Lobby from "../views/Lobby";
import History from "../views/History";
import Leaders from "../views/Leaders";
import Admin from "../views/Admin";
import HowToPlay from "../views/HowToPlay";
import HallOfFame from "../views/HallOfFame";
import Nft, { Game, Player, Rewards, Team } from "../helpers/types";
import { fn_url, teams } from "../helpers/config";
import Shop from "../views/Shop";
import ShopTokens from "../components/Shop/ShopTokens";
import Leaderteams from "../views/Leaderteams";
import Join from "../views/Join";
import Joinpay from "../views/Joinpay";


type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
    | "connect"
    | "disconnect"
    | "signTransaction"
    | "signAllTransactions"
    | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}

export interface PhantomProvider {
  publicKey: PublicKey | null;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
      message: Uint8Array | string,
      display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

export interface Wallet {
  key: string | undefined,
  provider: any | undefined,
  connect: any | undefined,
  disconnect: any | undefined,
  player: Player,
}

export const WalletContext = React.createContext<Wallet>(null!);

export default function App(props: {screen: any}) {
  const [provider, setProvider] = useState<PhantomProvider | undefined>(undefined);
  const [walletKey, setWalletKey] = useState<string | undefined>(undefined);

  const [sol, setSol] = useState<number | undefined>(undefined);
  const [nfts, setNfts] = useState<Nft[] | undefined>(undefined);
  const [tokens, setTokens] = useState(0);
  const [nextReward, setNextReward] = useState<Game>();
  const [team, setTeam] = useState<Team | undefined>(undefined);
  const [games, setGames] = useState<Game[]>([]);

  const getProvider = (): PhantomProvider | undefined => {
    if ("solana" in window) {
      // @ts-ignore
      const provider = window.solana as any;
      if (provider.isPhantom) return provider as PhantomProvider;
    }
  };

  const connectWallet = async () => {
    // @ts-ignore
    const { solana } = window;

    if (solana) {
      try {
        const response = await solana.connect({onlyIfTrusted: false});
        let key = response.publicKey.toString();
        console.log("wallet account ", key);
        setWalletKey(key);
        loadNfts(key);
        loadRewards(key);
        loadTeam(key);
        loadGames(key);
      } catch (err) {
        // { code: 4001, message: 'User rejected the request.' }
      }
    }
  };

  const disconnectWallet = async () => {
    // @ts-ignore
    const { solana } = window;

    if (walletKey && solana) {
      await (solana as PhantomProvider).disconnect();
      setWalletKey(undefined);
      setSol(undefined);
      setNfts(undefined);
      setTeam(undefined);
    }
  };

  // detect phantom provider exists
  useEffect(() => {
    const init = async () => {
      const provider = getProvider();
      if (provider) {
        // await connectWallet();
        setProvider(provider);
      }
      else setProvider(undefined);
    }
    init();
  }, []);

  const loadNfts = async (walletKey: string) => {
    console.log('looking up sol balance and nfts from: ', walletKey);
    let connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
    connection.getBalance(new PublicKey(walletKey)).then(function (value) {
      setSol(Math.floor(value / 10000000) / 100);
    })

    let metaConnection = new MetaConnection('mainnet-beta');
    const nftsmetadata = await Metadata.findDataByOwner(metaConnection, walletKey);
    const allCollections = teams.map(x => x.collection);

    setNfts(nftsmetadata
        .filter(x => allCollections.includes(x.data.name.split(' #')[0]))
        .map((x: MetadataData): Nft => {
          let collection = x.data.name.split(' #')[0];
          let id = +x.data.name.split(' #')[1];
          return {
            collection: collection,
            id: id,
            name: x.data.name,
            image: 'https://nft-images.netlify.app/' + collection.replace(' ','') + '/image-' + id + '-75.png',
          };
        })
        .sort((a, b) => (+a.id - +b.id))
    );
  };

  const loadRewards = async (walletKey: string) => {
    console.log("Loading rewards ...");
    setNextReward(undefined);
    let rewards: Rewards = await fetch(fn_url + "rewards?walletKey=" + walletKey).then(response => response.json());
    setTokens(rewards.balance);
    if (rewards.claims.length > 0) {
      setNextReward(rewards.claims[0]);
    }
  }

  const loadTeam = async (walletKey: string) => {
    console.log('Loading team...');
    let resp = await fetch(fn_url + "team?walletKey=" + walletKey).then(response => response.json());
    setTeam(teams.find(x => x.name === resp.team));
  }

  const loadGames = async (walletKey: string) => {
    console.log("Loading games...");
    setGames([]);
    let games = await fetch(fn_url + "games?walletKey=" + walletKey).then(response => response.json());
    setGames(games);
  }

  let player : Player = {
    sol: sol,
    nfts: nfts,
    tokens: tokens,
    nextReward: nextReward,
    games: games,
    team: team,
    setTeam: setTeam,
    selectedNft: undefined,
    loadRewards: (() => loadRewards(walletKey!)),
    loadGames: (() => loadGames(walletKey!)),
  };

  let wallet = {key: walletKey, provider: provider, connect: connectWallet, disconnect: disconnectWallet, player: player };

  return (
      <WalletContext.Provider value={wallet}>
          <div className="back-image"/>
          <div className="App">
            { (props.screen === 'lobby') && <Lobby/> }
            { (props.screen === 'history') && <History/> }
            { (props.screen === 'leaders') && <Leaders/> }
            { (props.screen === 'leaderteams') && <Leaderteams/> }
            { (props.screen === 'halloffame') && <HallOfFame/> }
            { (props.screen === 'join') && <Join/> }
            { (props.screen === 'joinpay') && <Joinpay/> }
            { (props.screen === 'admin') && <Admin/> }
            { (props.screen === 'howtoplay') && <HowToPlay/> }
            { (props.screen === 'shop') && <Shop/> }
            { (props.screen === 'shoptokens') && <ShopTokens/> }
          </div>
      </WalletContext.Provider>
  );
}
