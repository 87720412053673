import React, { useState } from "react";

export default function TeamSelectorIcon(props: {image: string, title: string, selectFn: any}) {
    let [selected, setSelected] = useState(false);

    return (
        <img alt='' src={props.image} title={props.title} width='100px' height='100px'
             style={{borderRadius: '50px', border: selected ? 'solid 2px red' : 'solid transparent 2px', cursor: 'pointer'}}
             onMouseOver={() => setSelected(true)}
             onMouseLeave={() => setSelected(false)}
             onClick={() => props.selectFn()}
        />
    );
}
