import { Game, Transaction } from "../../../helpers/types";

export default function HistoryGameTransactionsLine(props: { transaction: Transaction, game: Game }) {
    const tx = props.transaction;
    return (
        <div style={{
            display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '10px',
            backgroundColor: '#270238', height: '30px', width: '750px', color: '#ffce00', padding: '2px'
        }}>
            <div style={{width: '150px', marginLeft: '5px'}}>{new Date(tx.created_at).toLocaleString()}</div>
            <div style={{width: '70px'}}>{tx.reason}</div>
            <div style={{width: '50px', textAlign: 'right', color: tx.delta > 0 ? 'green' : 'orangered'}}>{tx.delta}</div>
            <div style={{width: '50px'}}>{tx.currency}</div>
            <div style={{width: '130px', display: 'flex'}}>
                <div style={{width: '110px'}}>{tx.walletKey.slice(0, 10) + '...'}</div>
                <div onClick={() => {navigator.clipboard.writeText(tx.walletKey)}}><img alt='' src='icon-copy.png'/></div>
            </div>
            <div style={{width: '230px'}}>{tx.signature && (
                <div style={{display: 'flex'}}>
                    <div style={{width: '210px'}}>{tx.signature.slice(0, 20) + '...'}</div>
                    <a target={tx.signature.slice(0,20)} href={'https://solscan.io/tx/' + tx.signature}><img alt='' src='solscan.png'/></a>
                </div>
                )
            }</div>
        </div>
    );
}