import React, { useEffect, useState } from "react";
import { WalletContext } from "../../../main/App";
import { Link } from "react-router-dom";
import { getRSTK, sendRSTK } from "../../../methods/tokens";
import { fn_url } from "../../../helpers/config";
import MainMenu from "../../MainMenu";

export default function ShopTokens() {
    const wallet = React.useContext(WalletContext);

    const [rstk, setRstk] = useState<number|undefined>(undefined);
    const [amount, setAmount] = useState<string>();

    const loadRstk = async () => {
        console.log('Loading rstk...');
        setRstk(await getRSTK(wallet.key));
    }

    useEffect(() => {
        loadRstk();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const changeAmount = (event: any) => {
        if (event.target.value === '') {
            setAmount('')
        }
        let amount = parseInt(event.target.value);
        if (isNaN(amount)) {
            setAmount('');
        } else {
            setAmount('' + amount);
        }
    }

    const convert = async () => {
        if (!wallet.key || !amount) { return; }
        console.log('convert ' + amount);
        let amountRstk = parseFloat(amount);
        let signature = await sendRSTK(wallet.provider, wallet.key, amountRstk);
        console.log('signature was ' + signature);
        if (signature !== null) {
            await fetch(fn_url + "tokens", {
                method: 'POST',
                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    walletKey: wallet.key,
                    signature: signature,
                    amount: amountRstk,
                })
            });

            setAmount('');
            setRstk(undefined);
            await new Promise(resolve => setTimeout(resolve, 5000));
            loadRstk();
            wallet.player.loadRewards();
        }
    }

    return (
        <div className="shop" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <MainMenu/>
            <div className='flexColumn' style={{width: '660px', padding: '40px 0px 0px 70px', justifyContent: 'flex-start'}}>
                <div className='flexColumn' style={{fontWeight: 'bold', padding: '20px', backgroundColor: '#270238', borderRadius: '20px', gap: '7px'}}>
                    <div className='flexRow' style={{gap: '7px'}}>
                        <div>Your balance:</div>
                        <img alt='' src='/restackcoin.png' height='32px' width='32px' style={{marginLeft: '10px'}}/>
                        <div>{rstk !== undefined ? rstk + ' $RSTK' : 'loading...'}</div>
                        <img alt='' src='/nitro-32.png' height='32px' width='32px' style={{marginLeft: '10px'}}/>
                        <div>{wallet.player.tokens} $NITRO</div>
                    </div>
                </div>

                <div className='flexColumn' style={{width: '375px', padding: '20px', backgroundColor: '#270238', borderRadius: '20px'}}>
                    <div className='flexRow'>
                        <img alt='' src='/restackcoin.png' height='32px' width='32px'/>
                        <div>&gt;</div>
                        <img alt='' src='/nitro-32.png' height='32px' width='32px'/>
                    </div>
                    <div style={{fontWeight: 'bold', fontSize: '20px'}}>Convert Restack Coins to $NITRO</div>
                    <div className='flexRow'>
                        <input className='textInput' value={amount} onChange={(event) => changeAmount(event)}/>
                    </div>
                    <button className='playButton' style={{marginTop: '5px'}} onClick={convert}>Convert</button>
                </div>

                <div className='flexColumn' style={{width: '375px', padding: '20px', backgroundColor: '#270238', borderRadius: '20px', gap: '7px'}}>
                    <div style={{marginBottom: '10px', fontWeight: 'bold'}}>How this works?</div>
                    <div style={{width: '100%', textAlign: 'left'}}>1. Buy some Restack Coins from <a target='restackcoin' href='https://restackcoin.com'>Restackcoin.com</a></div>
                    <div style={{width: '100%', textAlign: 'left'}}>2. Convert your Restack coins to $NITRO here</div>
                    <div style={{width: '100%', textAlign: 'left'}}>3. Use $NITRO to buy items in the Solracer shop</div>
                    <div style={{fontWeight: 'bold'}}>VROOM!</div>
                </div>

                <Link to='/shop'>&lt; back</Link>
            </div>
        </div>
    );
}
