import React from "react";
import WalletInfo from "../../WalletInfo";
import BuyPanel from "../../Panels/BuyPanel";
import RewardsPanel from "../../Panels/RewardsPanel";
import WalletPanel from "../../Panels/WalletPanel";
import { WalletContext } from "../../../main/App";
import ShopPanel from "../../Panels/ShopPanel";
import "./index.css";
import TeamSelector from "../../Team/TeamSelector";
import TeamHelpText from "../../Team/TeamHelpText";
import TeamInfo from "../../Team/TeamInfo";

export default function LobbyRight() {
    let wallet = React.useContext(WalletContext);

    return (
        <div className="wallet" style={{width: '280px', textAlign: 'center'}}>
            {wallet.key ?
                (wallet.player.team ?
                    <div>
                        <WalletPanel/>
                        <WalletInfo/>
                        <BuyPanel/>
                        <TeamInfo/>
                        <RewardsPanel/>
                        <ShopPanel/>
                    </div>
                : wallet.player.team === null ?
                    <div>
                        <WalletPanel/>
                        <TeamSelector/>
                        <TeamHelpText/>
                    </div>
                :
                    <div>
                        <WalletPanel/>
                        <div>loading...</div>
                    </div>
                )
                :
                <div className='flexColumn'>
                    <div style={{marginTop: '20px', fontSize: '30px', fontWeight: 'bold', fontStyle: 'italic'}}>Let's
                        Play!
                    </div>
                    <WalletPanel/>
                    <div style={{fontSize: '20px', width: '75%'}}>
                        To play Solracer, connect your Phantom Wallet :)
                    </div>

                </div>
            }
        </div>
    );
}
