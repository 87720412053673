import MainMenu from "../../components/MainMenu";
import "./index.css";
import React, {useEffect, useState} from "react";
import {Game} from "../../helpers/types";
import HistoryGame from "../../components/History/HistoryGame";
import HistoryOverview from "../../components/History/HistoryOverview";
import { fn_url } from "../../helpers/config";

export default function History() {
    const [games, setGames] = useState<Game[]>([]);
    const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);
    const [gamesPlayed, setGamesPlayed] = useState<number>();

    const loadHistory = async () => {
        console.log("Loading history...");
        let history = await fetch(fn_url + "history").then(response => response.json());
        setGames(history.games);
        setGamesPlayed(history.gamesPlayed);
    }

    const selectGame = (id: number | undefined) => {
        console.log('selected game ' + id);
        setSelectedGame(games.find(x => x.id === id));
    }

    useEffect(() => {
        loadHistory();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="history" style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                { (selectedGame === undefined) && <HistoryOverview games={games} selectGame={selectGame} gamesPlayed={gamesPlayed}/> }
                { (selectedGame !== undefined) && <HistoryGame game={selectedGame} selectGame={selectGame} /> }
            </div>
        </div>
    );
}
