import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainMenu from "../../components/MainMenu";
import { imageForTeam } from "../../helpers/nft-utils";
import { fn_url } from "../../helpers/config";

export default function Leaderteams() {
    const [teams, setTeams] = useState<any[]>([]);

    const loadTeams = async () => {
        console.log("Loading teams...");
        let teams = await fetch(fn_url + "leaderteams").then(response => response.json());
        setTeams(teams);
    }

    useEffect(() => {
        loadTeams();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const month_lowercase = new Date().toLocaleString('en-us', { month: 'long' });
    const month = month_lowercase[0].toUpperCase() + month_lowercase.slice(1);

    return (
        <div className="leaders" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                <div style={{display: 'flex'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Best teams in { month }
                    </div>
                </div>
                <div className='flexColumn' style={{gap: '10px', alignItems: 'flex-start'}}>
                    {teams.slice(0, 6).map(team =>
                        <div className='flexRow' key={team.winner} style={{gap: '20px', padding: '2px 5px 2px 10px', fontSize: '20px', justifyContent: 'flex-start'}}>
                            <div style={{width: '20px', marginLeft: '10px'}}>{team.placing}.</div>
                            <div className='flexRow' style={{width: '250px', gap: '10px', justifyContent: 'flex-start'}}>
                                <img alt="" height="50px" title={team.winnerTeam} src={imageForTeam(team.winnerTeam)} style={{marginBottom: '0', borderRadius: '25px'}}/>
                                <div>{team.winnerTeam}</div>
                            </div>
                            <div style={{width: '24px', height: '32px', display: 'flex'}}>
                                { team.placing === 1 && (<img alt='' style={{height: '32px'}} src='trophy-gold.png' />)}
                                { team.placing === 2 && (<img alt='' style={{height: '32px'}} src='trophy-silver.png' />)}
                                { team.placing === 3 && (<img alt='' style={{height: '32px'}} src='trophy-bronze.png' />)}
                            </div>
                            <div className='flexRow' style={{width: '75px', gap: '5px', justifyContent: 'flex-start'}}>
                                <div>{team.wins} win{team.wins > 1 && 's'}</div>
                            </div>
                        </div>
                    )}
                </div>
                <div style={{marginTop: '20px'}}><Link to='/leaders'>Show Players</Link></div>
            </div>
        </div>
    );
}
