import MainMenu from "../../components/MainMenu";
import React, { useEffect, useState } from "react";
import { Leader } from "../../helpers/types";
import { imageForNft } from "../../helpers/nft-utils";
import { fn_url } from "../../helpers/config";
import "./index.css";
import { Link } from "react-router-dom";

export default function Leaders() {
    const [leaders, setLeaders] = useState<Leader[]>([]);

    const loadLeaders = async () => {
        console.log("Loading leaders...");
        let leaders = await fetch(fn_url + "leaders").then(response => response.json());
        setLeaders(leaders);
    }

    useEffect(() => {
        loadLeaders();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const month_lowercase = new Date().toLocaleString('en-us', { month: 'long' });
    const month = month_lowercase[0].toUpperCase() + month_lowercase.slice(1);

    const solcoinText = (placing: number) => {
        return 'revshare ' + (placing === 1 ? '5%' : placing === 2 ? '3%' : placing === 3 ? '2%' : '0%')
    }

    return (
        <div className="leaders" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                <div style={{display: 'flex'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Best Solracers in { month }
                    </div>
                </div>
                {leaders.map(leader =>
                    <div key={leader.winner} style={{display: 'flex', gap: '20px', padding: '5px', fontSize: '20px'}}>
                        <div style={{width: '20px'}}>
                            {leader.placing}.
                        </div>
                        <div style={{width: '210px', display: 'flex', gap: '10px'}}>
                            <div><img alt="" width="30px" height="30px" src={imageForNft(leader.winner)}/></div>
                            <div>{leader.winner}</div>
                        </div>
                        <div style={{width: '25px'}}>
                            { leader.placing === 1 && (<img alt='trophy' style={{height: '32px'}} src='trophy-gold.png' />)}
                            { leader.placing === 2 && (<img alt='trophy' style={{height: '32px'}} src='trophy-silver.png' />)}
                            { leader.placing === 3 && (<img alt='trophy' style={{height: '32px'}} src='trophy-bronze.png' />)}
                        </div>
                        <div style={{width: '70px'}}>
                            {leader.wins} {leader.wins === 1 ? 'win' : 'wins'}
                        </div>
                        <div style={{width: '100px'}}>
                            {leader.placing <= 3 &&
                                <img alt='' title={solcoinText(leader.placing)} src='solcoin.png' style={{marginTop: '5px', height: '24px'}}/>
                            }
                        </div>
                    </div>
                )}
                <div style={{marginTop: '20px'}}><Link to='/leaderteams'>Show Teams</Link></div>
            </div>
        </div>
    );
}
