import { Game } from "../../../helpers/types";
import { imageForNft } from "../../../helpers/nft-utils";
import React from "react";

export default function HistoryOverview(props: { games: Game[], selectGame: any, gamesPlayed: any }) {
    const games = props.games;
    const selectGame = props.selectGame;
    const gamesPlayed = props.gamesPlayed;

    return (
        <div>
            <div style={{fontSize: '30px', marginBottom: '20px'}}>
                Race History
            </div>
            {games.map(game =>
                <div key={game.id} style={{display: 'flex', gap: '20px', padding: '5px', fontSize: '20px'}}>
                    <div style={{width: '150px'}}>
                        <button className="link-button" onClick={() => selectGame(game.id)}>{game.name}</button>
                    </div>
                    <div style={{width: '180px'}}>{new Date(game.played_at).toLocaleString()}</div>
                    <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
                        <div><img alt="" width="30px" height="30px" src={imageForNft(game.winner)}/></div>
                        <div>{game.winner}</div>
                    </div>
                </div>
            )}

            {gamesPlayed > 0 &&
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <div style={{fontSize: '20px'}}>
                        Total games played this month:
                    </div>
                    <div style={{fontSize: '30px', fontWeight: 'bold', marginLeft: '15px'}}>
                        {gamesPlayed}
                    </div>
                    <div style={{marginTop: '10px'}}>
                        {gamesPlayed > 100 && (
                            <img alt='wow' src='wow-64.png'/>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}