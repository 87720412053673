import { imageForNft } from "../../../helpers/nft-utils";
import { Game } from "../../../helpers/types";
import { isAdmin } from "../../../methods/authorize";
import React, { useState } from "react";
import HistoryGamePlacings from "../HistoryGamePlacings";
import HistoryGameTransactions from "../HistoryGameTransactions";
import { WalletContext } from "../../../main/App";
import { unity_url } from "../../../helpers/config";

export default function HistoryGame(props: {game: Game, selectGame: any}) {
    let wallet = React.useContext(WalletContext);

    const [adminMode, setAdminMode] = useState<boolean>(false);

    const game = props.game;
    const selectGame = props.selectGame;

    const openUnityClient = async () => {
        window.open( unity_url + game.results[0].key, 'unity');
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>

            {/* top banner */}
            <div style={{display: 'flex', gap: '30px'}}>
                <div>
                    <div style={{fontSize: '30px', fontWeight: 'bold'}}>{game.name}</div>
                    <div style={{fontSize: '15px'}}>Played at {new Date(game.played_at).toLocaleString()}</div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '20px', color: '#ffce00'}}>
                    <img alt="" style={{height: '60px'}} src={imageForNft(game.winner)}/>
                    <div>
                        <div style={{fontSize: '25px', fontWeight: 'bold'}}>WINNER</div>
                        <div style={{}}>{game.winner}</div>
                    </div>
                </div>
            </div>

            {/* placings grid */}
            {adminMode ?
                <HistoryGameTransactions game={game}/> :
                <HistoryGamePlacings game={game}/>
            }

            {/* bottom nav */}
            <div style={{width: '450px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                <button className="playButton" onClick={openUnityClient} style={{marginTop: '15px', width: '150px'}}>REPLAY</button>
                {isAdmin(wallet.key) && <button className="adminButton" onClick={() => setAdminMode(!adminMode)} style={{marginTop: '15px', width: '150px'}}>
                    {adminMode ? 'PLACINGS' : 'TRANSACTIONS'}
                </button>}
            </div>

            {/*back button*/}
            <div style={{marginTop: '5px'}}>
                <button className="link-button" onClick={() => selectGame(undefined)}>&lt; back </button>
            </div>
        </div>
    );
}
