import { imageForNft } from "../../../helpers/nft-utils";
import { Game } from "../../../helpers/types";
import React from "react";
import { WalletContext } from "../../../main/App";

export default function HistoryGamePlacingsLine(props: { player: any, game: Game, numPlayers: number}) {
    let wallet = React.useContext(WalletContext);
    const p = props.player;

    return (
        <div style={{
            display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '10px',
            backgroundColor: '#270238', width: '470px', color: '#ffce00',
            padding: '2px', border: '1px solid ' + (p.walletKey === wallet.key ? 'green' : 'transparent')
        }}>
            <div style={{height: '30px'}}>
                <img alt="" style={{height: '30px', width: '30px'}} src={imageForNft(p.nft)}/>
            </div>
            <div style={{width: '150px'}}>
                {p.nft}
            </div>
            <div style={{width: '120px'}}>
                Place: {p.placing} of {props.numPlayers}
            </div>
            <div style={{width: '160px'}}>
                {p.placing === 1 && props.game.mode === 'FREE' && 'Reward: 1 $NITRO'}
                {p.placing === 1 && props.game.mode === 'SOL' && 'Reward: ' + props.game.payout + ' SOL'}
            </div>
        </div>
    );
}