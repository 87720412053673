import NftSelector from "../NftSelector";
import React from "react";
import { WalletContext } from "../../main/App";

export default function WalletInfo() {
    const wallet = React.useContext(WalletContext);
    const teamNfts = wallet.player.nfts?.filter(x => x.collection === wallet.player.team!.collection);
    console.log(teamNfts);
    return (
        !wallet.player.nfts ?
            <div style={{height: '120px'}}><p style={{textAlign: 'center'}}>loading NFTs...</p></div>
            :
            <div style={{marginBottom: '10px'}}>
                <p style={{textAlign: "center"}}>
                    <b>Your wallet:</b> {teamNfts?.length} {wallet.player.team!.name}
                </p>
                {wallet.player.team && teamNfts && teamNfts?.length > 0 &&
                    <NftSelector message='Select an NFT to race' pagesize={3}/>
                }
            </div>
    );
}
