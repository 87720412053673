import React from "react";
import { WalletContext } from "../../../main/App";
import { Link } from "react-router-dom";

export default function ShopBalance() {
    const wallet = React.useContext(WalletContext);

    return (
        <div className='flexRow' style={{gap: '5px', fontWeight: 'bold', padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.636)', borderRadius: '20px'}}>
            <div>Your balance:</div>
            <img alt='' src='/nitro-32.png' height='32px' width='32px'/>
            <div>{wallet.player.tokens} $NITRO</div>
            <div style={{marginLeft: '15px'}}>
                <Link to='/shoptokens'><button className="playButton">Get $NITRO</button></Link>
            </div>
        </div>
    );
}
