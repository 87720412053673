import { Team } from "../types";

export const fn_url = 'https://fn.solracer.io/.netlify/functions/';
export const unity_url = 'https://i.simmer.io/@tr0n/solracer?key=';
export const rake_percent = 10;
export const collection = 'Solracer';
export const collection_H = collection + ' #';
export const waiting_reload_seconds = 30;
export const ready_at_seconds = 10;
export const race_at_seconds = 60;
export const finished_at_seconds = 90;
export const team_entry_fee = 5000;

export const teams : Team[] = [
    {
        name: 'Apex Ducks',
        image: 'team_apexducks.png',
        collection: 'ApexDucks',
        marketplace: 'apexducks',
    },
    {
        name: 'Bad Dog Syndicate',
        image: 'team_bads.webp',
        collection: 'BADS',
        marketplace: 'bads',
    },
    {
        name: 'Cute Dragons',
        image: 'team_cutedragons.webp',
        collection: 'Cute Dragon',
        marketplace: 'cute_dragon_social_club',
    },
    {
        name: 'Digi Dinos',
        image: 'team_digi_dino.webp',
        collection: 'Digi Dino',
        marketplace: 'digi_dino',
    },
]