import React from "react";
import { WalletContext } from "../../../main/App";

export default function TeamChange() {
    const wallet = React.useContext(WalletContext);

    return (
        <div className='flexColumn' style={{gap: '10px', marginTop: '15px', marginBottom: '10px'}}>
            <div>
                <b>Your team:</b> {wallet.player.team?.name}
            </div>
            <img alt='' src={wallet.player.team?.image} title={'Team ' + wallet.player.team?.name} width='100px' height='100px' style={{borderRadius: '50px'}}/>
            <div>
                <button className="clearButton" onClick={() => wallet.player.setTeam(null)}>Change&nbsp;team</button>
            </div>
        </div>
    );
}
